<template>
  <div>

    <br>
    <br>
    <br>
    <br v-if="patentes.length == 0">

    <template v-if="patentes.length != 0">
      <v-row justify="center">

        <!-- INICIO DIALOG WEB -->
        <v-dialog v-if="screen > 767" v-model="dialog" width="70%">
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn v-bind="attrs" v-on="on" :class="consulta">
                <v-icon> mdi-cart-outline </v-icon>
                <span style="margin-left: 4%;">Ver carrito</span>
              </v-btn>
            </div>
            <!-- <v-btn v-if="(e1 == 0)" dark style="margin-left: 1%; background-color: #0069B6;" @click="Consultar()">
              Consulta tu SOAP
            </v-btn> -->
          </template>
          <v-card>
            <v-card-title>
              <div class="title">
                <b class="titles1">Estás contratando un</b>
                <b class="titles2">SOAP</b>
                <b class="titles1">para los siguientes</b>
                <b class="titles2">vehículos:</b>
              </div>
            </v-card-title>
            <v-card-text>
              <template>
                <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
                  :items-per-page="5" class="elevation-1 table" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">

                  <template v-slot:top>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">
                          <div class="titleBox">
                            <b class="title1">¿Estás seguro de </b>
                            <b class="title2">eliminar</b>
                            <b class="title1">este producto?</b>
                          </div>
                        </v-card-title>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                          <v-btn class="btnConfirmPopUp" text @click="deleteItemConfirm">Confirmar</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <div style="cursor: pointer;" @click="deleteItem(item)">
                      <v-icon small class="mr-2 trash">
                        mdi-delete
                      </v-icon>
                      <span style="color:red;">Eliminar</span>
                    </div>
                    
                  </template>

                  <template v-slot:item.price="{ item }">
                    <label style="margin:auto">{{ formatPrice(item.price) }}</label>
                  </template>


                </v-data-table>

              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btnClose" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- FIN DIALOG WEB -->



        <!-- INICIO DIALOG MOBILE -->
        <v-dialog v-else v-model="dialog" width="100%">
          <template v-slot:activator="{ on, attrs }">
            <div :class="cartBox">
              <v-btn v-bind="attrs" v-on="on" class="btnConsultar2">
                <v-icon> mdi-cart-outline </v-icon>
                <span style="margin-left: 4%;font-family: 'Arial', sans-serif;">Ver carrito</span>
            </v-btn>
            </div>
            
            <!-- <v-btn v-if="(e1 == 0)" dark style="margin-left: 1%; background-color: #0069B6;" @click="Consultar()">
              Consulta tu SOAP
            </v-btn> -->
          </template>
          <v-card>
            <v-card-title>
              <div class="titleMobile text-center">
                <b class="titles1Mobile">Estás contratando un <span style="color:rgba(51,51,51, 1)">SOAP</span> <br> para los
                  siguientes <span style="color:rgba(51,51,51, 1)">vehículos:</span></b>
              </div>
            </v-card-title>
            <v-card-text>
              <template>
                <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
                  :items-per-page="5" class="elevation-1 table" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">
                  <template v-slot:top>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">
                          <div class="titleBox">
                            <b class="title1">¿Estás seguro de </b>
                            <b class="title2">eliminar</b>
                            <b class="title1">este producto?</b>
                          </div>
                        </v-card-title>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                          <v-btn class="btnConfirmPopUp" text @click="deleteItemConfirm">Confirmar</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2 trash" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>

                  <template v-slot:item.price="{ item }">
                    <label style="margin:auto">{{ formatPrice(item.price) }}</label>
                  </template>


                </v-data-table>

              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btnClose" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- FIN DIALOG MOVIL -->

      </v-row>
    </template>

  </div>
</template>

<script>
// import swal from "sweetalert";
// import ShieldService from "../services/shield.service";

import Vue from "vue";
import CryptoJS from 'crypto-js';
import { VueReCaptcha } from 'vue-recaptcha-v3'

const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "InitForm",
  props: ['patentes'],
  components: {
  },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    screen: window.screen.width,
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    consulta: '',
    cartBox: '',
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'carData.patente',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'carData.type', align: 'center', sortable: false },
      { text: 'Modelo', value: 'carData.model', align: 'center', sortable: false },
      { text: 'Correo', value: 'propietarioData.email', align: 'center', sortable: false },
      { text: 'Valor SOAP', value: 'carData.price', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center' },
    ]
    ,
  }),
  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true
    },
    async deleteItemConfirm() {

      this.patentes.splice(this.editedIndex, 1);
      this.closeDelete();

      const arrayShoppingCartLocal = JSON.stringify(this.patentes);
      const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
      localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);

      this.$emit("reload");
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    Consultar() {
     window.location.href = `/consulta`
      // this.$router.push({ path: `/consulta` });
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
  },
  async mounted() {

    if (this.screen > 767) {
      this.consulta = 'btnConsultar'
      this.cartBox = 'cartBox'
    } else if (this.screen <= 767) {
      this.consulta = 'btnConsultar2'
      this.cartBox = 'cartBox2'
    }
  },
};
</script>

<style lang="css" scoped>
.btnConsultar {
  text-transform: none;
  background-color: rgba(237,7,34, 1) !important;
  color: white;
  border-radius: 6px;
  padding: 4%;
  font-weight: 600;
}

.btnConsultar2 {
  margin-left: 10%;
  margin-top: -2%;
  text-transform: none;
  background-color: rgba(237,7,34, 1) !important;
  color: white;
  border-radius: 6px;
  padding: 4%;
  font-weight: 600;
}

.title {
  display: flex;
  width: 1000px;
  justify-content: center;
  margin: auto;
}

.titleMobile {
  width: 100%;
  margin-left: 4%;
  margin-bottom: 4%;
}

.titles1 {
  padding-left: 0.5%;
  color: rgba(51,51,51, 1);
}

.titles2 {
  margin-left: 0.5%;
  color: rgba(51,51,51, 1);
}

.titles1Mobile {
  /* margin-left: 0.5%; */
  color: rgba(51,51,51, 1);
}

.btnClose {
  color: rgba(51,51,51, 1);
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  letter-spacing: normal;
}

.v-data-footer {
  background-color: blue !important;
  color: white;
}

.tableHeaders {
  background-color: rgba(51,51,51, 1);
  color: white !important;
  border: 2px solid rgba(51,51,51, 1);
}

.text-center {
  text-align: center;
}

.table {
  border: 2px solid rgba(51,51,51, 1);
}

.cartBox{
  position: absolute;
  right: 23%;
}

.btnClosePopUp {
  background-color: none;
  color: rgba(237,7,34,1);
  border: 1px solid rgba(237,7,34,1);
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
}

.btnConfirmPopUp {
  background-color: rgba(237,7,34,1);
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
}

.title1 {
  color: rgba(51,51,51, 1);
}

.title2 {
  color: rgba(51,51,51, 1);
  /* margin-left: 1%; */
  margin-right: 1%;
}

.titleBox {
  width: 100%;
  text-align: center;
}

.trash {
  color: red !important;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: rgba(51,51,51, 1) !important;
  color: white !important;
}
</style>